<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { reqMethods } from '../../../state/helpers';
import axios from 'axios';
import Swal from "sweetalert2";
/**
 * Email-read component
 */
export default {
    setup() {
        return {
            v$: useVuelidate()
        };
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Détails Vehicule",
            items: [
                {
                    text: "Vehicules recherchés",
                    href: "/alert/list-alert"
                },
                {
                    text: "Détails",
                    active: true
                }
            ],
            car: {},
            coversrc: null,
            pertinence: null,
            status: null,
            alert: {},
            send: true,
            reqError: null,
            reqResponse: null,
            submitted: false,

        };
    },
    created() {
    },
    validations: {
    },
    methods: {
        ...reqMethods,
        getCarDetails() {
            this.car = JSON.parse(localStorage.getItem('car'));
            this.status = this.car?.statut;
        },
        async setStatus(status) {
            const formData = new FormData();
            formData.append('id', this.car?.id);
            formData.append('statut', status);
            try {
                const response = await axios.put(`https://api.alcit.sims-technologie.com/api/v1/vehicule/vehicule/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': localStorage.getItem('token'),
                    },
                });
                Swal.fire(response.data.message, "le statut à été changé!", "success");
                this.status = status;
            } catch (error) {
                console.log(error);
                Swal.fire({
                    title: "Oops...",
                    text: "Erreur lors du changement de statut",
                    icon: "error",
                    confirmButtonClass: "btn btn-primary w-xs mt-2",
                    buttonsStyling: false,
                    showCloseButton: true,
                });
            }

        }
    },
    mounted() {
        this.getCarDetails();
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <img src="@/assets/images/sound.png" id="imageId" class="d-none" alt="">
            <div class="col-12 mb-3">
                <div class="card">
                    <div class="row justify-content-between">
                        <div class="col-md-7">
                            <div class="btn-toolbar p-3" role="toolbar">
                                <button type="button" @click="deleteThisAlert()" class="btn btn-danger me-2">
                                    <i class="mdi mdi-trash-can"></i>
                                    Désactiver
                                </button>

                            </div>
                        </div>
                        <div class="col-md-5 d-flex justify-content-end">
                            <b-dropdown split text="Status" variant="warning" class="p-3">
                                <b-dropdown-item @click="setStatus('rechercher')">Rechercher</b-dropdown-item>
                                <b-dropdown-item @click="setStatus('non_rechercher')">Non Rechercher</b-dropdown-item>
                            </b-dropdown>
                            <strong v-if="status == 'rechercher'" class="text-danger p-3 mt-2">
                                Rechercher
                            </strong>
                            <strong v-if="status == 'non_rechercher'" class="text-success p-3 mt-2">
                                Non Rechercher
                            </strong>
                        </div>
                    </div>
                    <div class="card-body">
                        <div v-if="car?.photo?.length > 0">
                            <b-card no-body class="mb-1">
                                <b-card-header v-b-toggle.accordion-1 header-tag="header" role="tab">
                                    <h6 class="m-0">
                                        <a class="text-dark" href="javascript: void(0);">
                                            <strong>Images </strong>
                                        </a>
                                    </h6>
                                </b-card-header>
                                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <div class="row" v-viewer>
                                            <div class="col-xl-2 col-6" v-for="(photo, index) in car.photo" :key=index>
                                                <div class="card">
                                                    <img class="card-img-top img-fluid"
                                                        :src="`https://api.alcit.sims-technologie.com${photo.photos}`"
                                                        alt="Card image cap" />
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Plaque véhicule:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ car.immatriculation }}</h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Numéro de châssis:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ car.numero_chassis }}</h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Type:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info"> {{ car.type_vehicule }} </h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Marque:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ car.marque }} </h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Modèle:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ car.modele }} </h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Couleur:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ car.couleur }} </h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Année:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ car.annee }} </h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Statut:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 v-if="status == 'rechercher'" class="font-size-13 text-danger">Rechercher</h5>
                                <h5 v-if="status == 'non_rechercher'" class="font-size-13 text-success">Non Rechercher</h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Motif de recherche:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info"> {{ car.motif_recherche }} </h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Niveau dangerosité:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 v-if="car.niveau_dangerosite == 'normal'"
                                    class="font-size-13 badge badge-pill badge-soft-info"> Normal </h5>
                                <h5 v-if="car.niveau_dangerosite == 'dangereux'"
                                    class="font-size-13 badge badge-pill badge-soft-danger"> Dangereux </h5>
                                <h5 v-if="car.niveau_dangerosite == 'tres_dangereux'"
                                    class="font-size-13 badge badge-pill badge-soft-danger"> Très Dangereux </h5>
                            </div>
                        </div>
                        <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                                <strong class="font-size-13">Conduite à tenir:</strong>
                            </div>
                            <div class="col-md-10">
                                <h5 class="font-size-13 text-info">{{ car.conduite_a_tenir }} </h5>
                            </div>
                        </div>                  
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
